import { Layout } from '@hp/layout';
import { NotFound } from '@hp/main';
import { Navigation } from '@hp/navigation';
import { NextPage } from 'next';
import React from 'react';

const Index: NextPage = () => {
  return (
    <Layout metaRoute="default">
      <Navigation onlyLangSwitch />

      <NotFound />
    </Layout>
  );
};

export default Index;
